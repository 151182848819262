import classnames from 'classnames';
import Loadable from 'react-loadable';
import React from 'react';
import PropTypes from 'prop-types';

import Container from 'molecules/Container';
import Link from 'molecules/Link';

import './PageContent.scss';

const Heading = Loadable( {
  loader: () => import( 'molecules/PageHeading' ),
  loading: () => <div />
} );
const Footnotes = Loadable( {
  loader: () => import( 'organisms/Footnotes' ),
  loading: () => <div />
} );
const Paragraph = Loadable( {
  loader: () => import( 'molecules/PageParagraph' ),
  loading: () => <div />
} );

/**
 * Page Content.
 */
const propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
const PageContent = ( { data, title } ) => {

  return(

    <div
    className={ classnames( 'PageContent', {
      ['with-sidebar']: !! data.sidebar,
    } ) }>
      <Container>

        <div className="PageContent__hding f-page-title">
          <h1 className="txt">
            { title }
          </h1>
        </div>

        <div className="PageContent__body row">

          <div
          className={ classnames( 'PageContent__content', {
            ['col-md-9']: !! data.sidebar,
            ['col']: ! data.sidebar,
          } ) }
          >
            { !! data.content && data.content.map( section => {

              if( section._type === 'heading' ) {
                return <Heading { ...section } />
              }
              else if( section._type === 'paragraph' ) {
                return <Paragraph { ...section } />
              }
              else if( section._type === 'footnotes' ) {
                return <Footnotes { ...section } />
              }

            } ) }

          </div>

          { !! data.sidebar &&

            <div className="PageContent__sidebar col-md-3">

              <ul className="cleanlist">
                { !! data.sidebar.links.length && data.sidebar.links.map( link => (

                  <li>
                    <Link to={ link.page.slug.current }>
                      { link.text }
                    </Link>
                  </li>

                ) ) }
              </ul>

            </div>

          }

        </div>

      </Container>
    </div>

  )

};
PageContent.propTypes = propTypes;

export default PageContent;
