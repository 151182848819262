
import React from 'react';
import PropTypes from 'prop-types';

import Container from 'molecules/Container';
import WithBGImage from 'molecules/WithBGImage';
import './Hero.scss';

/**
 * Hero.
 */
const proptypes = {

}
const Hero = ( { data, size } ) => {

  return(

    <WithBGImage
    className={ `Hero is-${size || 'md'}` }
    image={ {
      src: !! data && data.bg.src ? data.bg.src : null,
      data: !! data ? data.bg : null,
    } }
    overlay={ true }
    >

      { /*
      <Container
      className="Hero__content"
      size="sm"
      >
        <h1 className="HeroHome__content-hline text-center">
          { data.fields.hline }
        </h1>
      </Container>
      */ }

    </WithBGImage>

  )

};
Hero.propTypes = proptypes;

export default Hero;
