import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Hero from 'organisms/Hero';
import PageContent from 'organisms/PageContent';
import RefTagger from 'molecules/RefTagger';
//import RefTagger from 'molecules/RefTagger';


/**
 * Default Template.
 */
const Default = ( { pageContext: page } ) => {

  const { content } = page;
  console.log( 'page=', page );

  // /* ------ = Output. = --------------------------------------------------------------------- */
  return(

    <div className={ `Default ${page.slug.current}`}>

      <section>
        <Hero data={ content.hero } />
        <PageContent
        title={ page.title } 
        data={ content }
        />
      </section>

    </div>

  )

};
export default Default;
